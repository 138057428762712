import React, { useState, useRef } from 'react';
import MapConfig from '../../../mapconfig';
import { Spin } from 'antd';
import {
 APIProvider,
 Map,
 AdvancedMarker,
 Pin,
} from '@vis.gl/react-google-maps';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { useGoogleMapsLoader } from '../../../services/GoogleMapService';

const libraries = ['places'];

const MapPicker = React.memo(({ onPlaceSelected }) => {
 const isLoaded = useGoogleMapsLoader();

 const [position, setPosition] = useState({ lat: 34.0083637, lng: -6.8538748 });

 const [placeName, setPlaceName] = useState('Rabat');
 const [placeURL, setPlaceURL] = useState('');
 const [placeAddress, setPlaceAddress] = useState('');
 const [placeRating, setPlaceRating] = useState(0);
 const [placePhoto, setPlacePhoto] = useState('');
 const [placeTypes, setPlaceTypes] = useState([]);

 const autocompleteRef = useRef(null);

 const handlePlaceSelect = (place) => {
  if (!place || !place.geometry || !place.geometry.location) {
   console.error(
    'Selected place is invalid or missing necessary location data.'
   );
   return; // Exit the function if no valid place data is available
  }

  // Extract location coordinates
  const latitude = place.geometry.location.lat();
  const longitude = place.geometry.location.lng();

  // Update the position and marker key
  setPosition({ lat: latitude, lng: longitude });
  // Extract additional place details, providing fallbacks where necessary
  setPlaceName(place.name || 'Name not available');
  setPlaceURL(place.url || '');
  setPlaceAddress(place.formatted_address || 'Address not available');
  setPlaceRating(place.rating || 0);
  setPlacePhoto(
   place.photos && place.photos.length > 0 ? place.photos[0].getUrl() : ''
  );
  setPlaceTypes(place.types || []);

  // Prepare the selectedPlace object for callback
  const selectedPlace = {
   latitude: latitude,
   longitude: longitude,
   placeName: place.name || 'Name not available',
   placeURL: place.url || '',
   placeAddress: place.formatted_address || 'Address not available',
   placeRating: place.rating || 0,
   placePhoto:
    place.photos && place.photos.length > 0 ? place.photos[0].getUrl() : '',
   placeTypes: place.types || [],
  };

  // Trigger the callback with the selected place data
  onPlaceSelected(selectedPlace);
 };

 const handleMarkerDragEnd = ({ latLng }) => {
  if (typeof onPlaceSelected === 'function') {
   setPlaceName('');
   setPlaceURL('');
   setPlaceAddress('');
   setPlaceRating(0);
   setPlacePhoto('');
   setPlaceTypes([]);
   onPlaceSelected({
    latitude: latLng.lat() || null,
    longitude: latLng.lng() || null,
    placeName: placeName,
    placeURL: placeURL,
    placeAddress: placeAddress,
    placeRating: placeRating,
    placePhoto: placePhoto,
    placeTypes: placeTypes,
   });
  }
 };

 if (!isLoaded) {
  return (
   <div className="loading">
    <Spin size="large" />
   </div>
  );
 }
 return (
  <APIProvider>
   <div
    style={{
     display: 'inline-block',
     width: '100%',
     height: '400px',
     borderRadius: '12px',
     overflow: 'hidden',
    }}
   >
    <Autocomplete
     onLoad={(autocomplete) => {
      autocompleteRef.current = autocomplete;
     }}
     onPlaceChanged={() => {
      if (autocompleteRef.current !== null) {
       const place = autocompleteRef.current.getPlace();
       handlePlaceSelect(place);
      }
     }}
     options={{
      componentRestrictions: { country: 'ma' }, // Restrict search to Morocco
     }}
    >
     <input
      placeholder="Indiquer une place"
      style={{
       width: '100%',
       padding: '0.5rem',
       borderTopLeftRadius: '12px',
       borderTopRightRadius: '12px',
       border: '1px solid #ddc7a8',
      }}
     />
    </Autocomplete>
    <Map
     key={position.lat + position.lng}
     defaultZoom={14}
     defaultCenter={position}
     mapId={MapConfig.MAP_ID}
    >
     <AdvancedMarker
      position={position}
      draggable
      onDragEnd={({ latLng }) => handleMarkerDragEnd({ latLng })}
     >
      <Pin
       background={'#aa7e42'}
       borderColor={'#2b2c32'}
       glyphColor={'#fbfbfb'}
      />
     </AdvancedMarker>
    </Map>
   </div>
  </APIProvider>
 );
});

export default MapPicker;
