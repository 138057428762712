const ClientConfig = {
 /*  URL: 'http://localhost:3000',
 URI: 'http://localhost:4000',
 FRONT_PORT: 3000, */
 URL: 'https://csapp.nextbedesign.com',
 URI: 'https://csapp.nextbedesign.com',
 FRONT_PORT: 443,
 BACK_PORT: 4000,
};

export default ClientConfig;
